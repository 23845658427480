import React, { Component } from "react";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import Video from "../components/video"

class IndexPage extends Component {
  constructor(props){
    super(props);
    this.state={
    };

  }

  componentDidMount(){
      window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
      window._adftrack.push({
          HttpHost: 'track.adform.net',
          pm: 1644357
      });
      (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
  }

  render(){
    return(
      <Layout>
        <Seo 
          title={'THERAMEX / Cacite'} 
          description={'Description'} 
        />
        <Helmet>
          <noscript>
          {`
              <p style="margin:0;padding:0;border:0;">
                  <img src="https://track.adform.net/Serving/TrackPoint/?pm=1644357" width="1" height="1" alt="" />
              </p>
          `}
          </noscript>
        </Helmet>
        <div className="col-12 p-xl-0">
          <Video />
        </div>
      </Layout>
    )
  }
}

export default IndexPage
